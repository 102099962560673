<template>
  <AppLayoutVue>
    <v-container>
      <v-row>
        <v-col md="6" class="justify-center">
          <v-card elevation="2">
            <v-card-title>Profile</v-card-title>
            <v-card-text>
              <p>
                <img
                  :src="$store.state.user.avatar"
                  class="rounded-circle"
                  alt=""
                />
              </p>
              <!-- <p>Name: {{ $store.state.user}}</p> -->
              <p>Name: {{ $store.state.user.name }}</p>
              <p>Email: {{ $store.state.user.email }}</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6" class="justify-center">
          <v-card elevation="2">
            <v-card-title>Payment Details</v-card-title>
            <v-card-text>
              <!-- <p>Name: {{ $store.state.user}}</p> -->
              <p>Card: **** **** **** 7312</p>
              <v-btn elevation="2" color="red darken-2" text>Delete</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </AppLayoutVue>
</template>

<script>
import AppLayoutVue from "../layouts/AppLayout.vue";

export default {
  components: { AppLayoutVue },
};
</script>
