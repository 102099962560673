<template>
  <v-facebook-login v-model="model" app-id="425432993284103" @login="onLogin" @sdk-init="handleSdkInit" version="v19.0"></v-facebook-login>
</template>

<script>
import axios from "axios";
import VFacebookLogin from 'vue-facebook-login-component';

export default {
  name: "FacebookLogin",
  components: {
    VFacebookLogin
  },
  data: () => ({
    FB: {},
    model: {},
    scope: {}
  }),
  methods: {
    onLogin(response) {
      this.$store.commit('setLoginProcedure', true);
      console.info('fbresponse');
      console.info(response);
      if (!response) {
        return;
      }
      let data = response.authResponse;
      data.type = 'facebook';
      this.$router.push('/');
      axios.post('/api/auth', data)
          .then(res => {
            console.info('auth');
            console.info(res)
            this.$store.commit('login', res.data);
          })
          .catch(err => {
            console.error(err)
          })
    },
    handleSdkInit({ FB, scope }) {
      this.FB = FB
      this.scope = scope
    }
  }
}


// window.fbAsyncInit = function() {
//   FB.init({
//     appId      : '{your-app-id}',
//     cookie     : true,
//     xfbml      : true,
//     version    : '{api-version}'
//   });
//
//   FB.AppEvents.logPageView();
//
// };
//
// (function(d, s, id){
//   var js, fjs = d.getElementsByTagName(s)[0];
//   if (d.getElementById(id)) {return;}
//   js = d.createElement(s); js.id = id;
//   js.src = "https://connect.facebook.net/en_US/sdk.js";
//   fjs.parentNode.insertBefore(js, fjs);
// }(document, 'script', 'facebook-jssdk'));


</script>

<style scoped>
  button.v-facebook-login {
    width: 100%;
    height: 50px;
  }
</style>