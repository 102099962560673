<template>
  <div>
    <GoogleLogin :params="params" :renderParams="renderParams" :onSuccess="onSuccess" :onFailure="onFailure"></GoogleLogin>
  </div>

</template>

<script>
import axios from 'axios';
import GoogleLogin from 'vue-google-login';

export default {
  name: "GoogleSignIn",
  components: {
    GoogleLogin
  },
  data() {
    return {
      // client_id is the only required property but you can add several more params, full list down bellow on the Auth api section
      params: {
        client_id: "386417534091-qc6fsd9h03q8e8d8cq1gqjr1sk1acjno"
      },
      // only needed if you want to render the button with the google ui
      renderParams: {
        maxWidth: 400,
        height: 50,
        longtitle: true,
        theme: 'dark',
      }
    }
  },
  methods: {
    onSuccess(googleUser) {
      this.$store.commit('setLoginProcedure', true);
      // This only gets the user information: id, name, imageUrl and email
      const profile = googleUser.getBasicProfile();
      const email = profile.getEmail();

      // if (email.indexOf('@ablebulgaria.org') === -1) {
      //   // TODO add error message
      //   return false;
      // }

      axios.post('/api/auth', {id: googleUser.getAuthResponse().id_token, type: 'google'})
          .then(res => {
            // this.loadSnippets();
            // this.createSnippetDialog = false;
            this.$store.commit('login', res.data);
            this.$router.push('/');
          })
          .catch(err => {
            console.error(err)
          })
    },
    onFailure(err) {
      console.error(err);
    }
  }
}
</script>

<style>
  .abcRioButton {
    width: 100% !important;
  }
</style>